<!--模板-->
<template>
  <div class="container">
    <div class="right-top">
      <div class="search">
        <div style="margin-right: 36px">
          <span style="margin-right: 5px">检查项目:</span>
          <a-select placeholder="请选择" style="width: 272px" @change="handleChange">
            <a-select-option value="jack"> Jack </a-select-option>
          </a-select>
        </div>
        <a-button type="primary" style="margin-right: 5px">查询</a-button>
        <a-button>重置</a-button>
      </div>
      <div class="newBuild">
        <a-button type="primary" @click="showModal">新建</a-button>
      </div>
    </div>
    <a-table :columns="columns" :data-source="data">
      <span slot="operation">
        <a>修改</a>
        <a-divider type="vertical" />
        <a>删除</a>
      </span>
    </a-table>
  </div>
</template>

<script>
// import component from '@/component/*.vue'
const columns = [
  {
    title: '序号',
    dataIndex: 'serialNum',
    key: 'serialNum',
    align: 'center',
  },
  {
    title: '检查内容',
    dataIndex: 'project',
    key: 'project',
    align: 'center',
  },
  {
    title: '权重',
    dataIndex: 'weight',
    key: 'weight',
    align: 'center',
  },
  {
    title: '所属检查项目',
    key: 'examineProject',
    dataIndex: 'examineProject',
    align: 'center',
  },
  {
    title: '更新时间',
    key: 'time',
    dataIndex: 'time',
    align: 'center',
  },
  {
    title: '操作',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'center',
  },
]
const data = [
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    weight: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    weight: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    weight: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    weight: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    weight: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    weight: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    weight: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    weight: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    weight: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    weight: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    weight: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    weight: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    detection: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    detection: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    detection: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    detection: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
  {
    key: '1',
    serialNum: '1',
    project: '项目一',
    detection: '3',
    examineProject: '100',
    time: '2017-10-31  23:12:00',
  },
]
export default {
  data() {
    return {
      data,
      columns,
    }
  },
  props: {
    showModals: {
      type: Function,
      default: null,
    },
  },
  components: {},
  computed: {},
  filters: {},
  methods: {
    showModal() {
      this.showModals()
    },
  },
  created() {},
  mounted() {},
}
</script>

<style lang="less" scoped>
// @import url(); 引入公共css
.right-top {
  margin-bottom: 53px;
  box-sizing: border-box;
  padding: 42px 30px 0 46px;
}
.search {
  display: flex;
  align-content: center;
}
.newBuild {
  display: flex;
  justify-content: flex-end;
}
</style>
